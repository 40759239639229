import { BaseFile } from "./BaseFile";

export class ExportedStockFile extends BaseFile
{    
    brands
    warehouses
    products
    categories

    constructor(params) {
        super(params)

        const { brands, warehouses, products, categories } = params

        this.brands = brands        
        this.warehouses = warehouses
        this.products = products
        this.categories = categories
    }

    serailize() {
        return {
            brand_ids: !!this.brands ? this.brands.map(item => item.id) : null,
            warehouse_ids: !!this.warehouses ? this.warehouses.map(item => item.id) : null,
            product_ids: !!this.products ? this.products.map(item => item.id) : null,
            category_ids: !!this.categories ? this.categories.map(item => item.id) : null,
        }
    }
}